import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as ANMIcon } from "./anm.svg";
import "./App.css";

require("dotenv").config();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center"

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: "1rem"
  },
});

class MainMenu extends Component {

  render() {
    const { classes } = this.props;
    const { type, nodeRef } = this.context;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <SvgIcon
              component={ANMIcon}
              className={classes.menuButton}
            />
            <Typography variant="h6" className={classes.title}>plummet.io</Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

//export default withRouter(withStyles(styles)(MainMenu));
export default withStyles(styles)(MainMenu);
