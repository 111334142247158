import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import "@fontsource/michroma" // Defaults to weight 400.

import logo from './plummet_io_crop.png'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

//          style={{width: "30vw", height: "auto"}}
export default function Hero() {
  const classes = useStyles();

  return (
    <Container maxWidth="false" style={{backgroundColor: "#5D97FF"}}>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <img
          className={classes.media}
          src={logo}
          title="Partial Number Plate"
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Typography gutterBottom variant="subtitle1" component="h2" style={{fontWeight: 800, color: "white", fontFamily: "Michroma"}}>
          Applications and services
        </Typography>
      </Box>
    </Container>
  );
}
