import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard() {
  const classes = useStyles();

  function clickHandler(event) {
    location.href = "https://partialnumberplate.co.uk/";
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={clickHandler}>
        <CardMedia
          className={classes.media}
          image="/pnp.png"
          title="Partial Number Plate"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Partial Number Plate
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Need to find a UK registration plate but don't know all of the
            characters? Perhaps you've been involved in a hit-and-run (failed to
            stop) incident? This site can help.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={clickHandler}>
          Visit
        </Button>
      </CardActions>
    </Card>
  );
}
