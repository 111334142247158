import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import MainMenu from "./MainMenu.js";
import ANMCard from "./ANM_card.js";
import PNPCard from "./PNP_card.js";
import Hero from "./Hero.js";
import Divider from "@material-ui/core/Divider";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="left">
      Plummet Ltd. trading as plummet.io.<br/>
      Copyright © 2021 Plummet Ltd.<br/>
      All rights reserved.<br/>
      <span>
        Contact us:&nbsp;
        <a href="mailto:info@plummet.io" target="_top">
          info@plummet.io
        </a>
      </span>
    </Typography>
  );
}

export default function App() {
  return (
    <>      
      <Hero />
      <Container maxWidth="sm">
        <Box my={4} display="flex" flexDirection="row" justifyContent="center">
          <ANMCard />
        </Box>
        <Box my={4} display="flex" flexDirection="row" justifyContent="center">
          <PNPCard />
        </Box>
        <Divider />
        <Copyright />
      </Container>
    </>
  );
}
